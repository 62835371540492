import React from "react"
import BusinessInvoicing from "../../../components/body/pages/en-ng/business/invoicing"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const BusinessInvoicingPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/invoicing"}
      title="Streamline Your Invoicing | Kuda for Business"
      description="Manage your business finances effortlessly with Kuda's invoicing features. Create, send, & track invoices directly from your Kuda business account."
    />
    <BusinessInvoicing />
  </Layout>
)

export default BusinessInvoicingPage
